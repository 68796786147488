import React from 'react';
import styled from 'styled-components';
import { FiMapPin, FiAlertCircle } from 'react-icons/fi';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #f5f8fa;
  padding: 10px;
  border: 1px solid #ddd;
  font-weight: bold;
  text-align: left;
  color: #333;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 0.9em;
  color: #333;
`;

const Button = styled.button`
  background-color: #1a6fb3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #155a8a;
  }
`;

export { Table, TableHeader, TableRow, TableCell, Button };
