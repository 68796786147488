import styled from 'styled-components';

const PageContainer = styled.div`
  background-color: #e5e9f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #333;
  padding: 20px;
  box-sizing: border-box;
  gap: 24px;
`;

export default PageContainer;
