import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoute, IRoutesState, Route } from "@types";

const initialState: IRoutesState = {
  origin: null,
  destination: null,
  routes: [],
};

export const RoutesSlice = createSlice({
  name: "Routes",
  initialState,
  reducers: {
    defineOrigin: (state, action: PayloadAction<IRoute>) => {
      state.origin = action.payload;
    },
    defineDestination: (state, action: PayloadAction<IRoute>) => {
      state.destination = action.payload;
    },
    defineRoutes: (state, action: PayloadAction<Route[]>) => {
      state.routes = action.payload;
    },
  },
});

export const { defineOrigin, defineDestination, defineRoutes } =
  RoutesSlice.actions;

export default RoutesSlice.reducer;
