import { useEffect, useRef, useState } from "react";

import ButtonDropdown from "@rio-cloud/rio-uikit/ButtonDropdown";
import ExpanderPanel from "@rio-cloud/rio-uikit/ExpanderPanel";
import Button from "@rio-cloud/rio-uikit/Button";
import "./routes.css";
import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import ClearableInput from "@rio-cloud/rio-uikit/ClearableInput";
import Notification from "@rio-cloud/rio-uikit/Notification";
import Slider from "@rio-cloud/rio-uikit/Slider";
import NumberInput from "@rio-cloud/rio-uikit/NumberInput";
import ToggleButton from "@rio-cloud/rio-uikit/ToggleButton";
import Collapse from "@rio-cloud/rio-uikit/Collapse";
import DataTab from "@rio-cloud/rio-uikit/DataTab";
import DataTabs from "@rio-cloud/rio-uikit/DataTabs";
import { AutoSuggest } from "@rio-cloud/rio-uikit";
import Autosuggest from "../Autosuggest";
import { fetchAutosuggest, mountRoute } from "src/services/routes";
import { AutoSuggestSuggestion } from "@rio-cloud/rio-uikit/components/autosuggest/AutoSuggest";
import moment from "moment";
import { Route } from "@types";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import { useDispatch } from "react-redux";
import {
  defineDestination,
  defineOrigin,
  defineRoutes,
} from "src/store/routes/RoutesSlice";

export const Title = ({
  text,
  value,
}: {
  text: string;
  value: React.ReactNode;
}) => (
  <div>
    <div>{text}</div>
    <div className="text-bold text-size-18">{value}</div>
  </div>
);

type TProps = {
  onChangeRoutes: (routes: Route[], via: any[]) => void;
};

const RegisterRoute = ({ onChangeRoutes }: TProps) => {
  const dispatch = useDispatch();

  const [origin, setOrigin] = useState<any>(null);
  const [destination, setDestination] = useState<any>(null);
  const [routes, setRoutes] = useState<Route[]>([]);
  const [via] = useState([]);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");

  useEffect(() => {
    if (origin) {
      dispatch(
        defineOrigin({ lat: origin.lat, lng: origin.lng, label: origin.label })
      );
    }
    if (destination) {
      dispatch(
        defineDestination({
          lat: destination.lat,
          lng: destination.lng,
          label: destination.label,
        })
      );
    }
    if (origin && destination) {
      handleRoute();
    } else {
      setRoutes([]);
    }
  }, [origin, destination]);

  useEffect(() => {
    onChangeRoutes(routes, via);
    dispatch(defineRoutes(routes));
  }, [routes]);

  const handleRoute = async () => {
    if (origin && destination) {
      console.log({ origin, destination });
      const body = {
        origin: { lat: origin.lat, lng: origin.lng },
        destination: { lat: destination.lat, lng: destination.lng },
        via,
        vehicle: {},
        departureTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
      };
      const response = await mountRoute(body);
      setRoutes(response.routes);
      const { distance, duration } = (
        response.routes[0]?.sections || []
      ).reduce(
        (acum, curr) => {
          acum.distance += curr.summary.length;
          acum.duration += curr.summary.duration;
          return acum;
        },
        { distance: 0, duration: 0 }
      );
      setDistance(convertMetersToKm(distance));
      setDuration(convertSecondsToHours(duration));
    }
  };

  const convertSecondsToHours = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return `${hours}h${minutes}min`;
  };

  const convertMetersToKm = (valueInMeters: number) => {
    if (valueInMeters < 1000) {
      return `${valueInMeters} M`;
    } else {
      const valueInKm = (valueInMeters / 1000).toFixed(2);
      return `${valueInKm} Km`;
    }
  };

  const [openModal, setOpenModal] = useState(false);

  const [show, setShow] = useState(false);

  const DynamicContent = () => {
    return (
      <fieldset className="col-sm-4">
        <p>
          <Checkbox label="Rota com menor consumo energético" />
        </p>
        <p>
          <Checkbox label="Rota mais rápida" />
        </p>
        <p>
          <Checkbox label="Rota mais economica" />
        </p>
      </fieldset>
    );
  };

  const DynamicContentSimuilador = () => {
    return (
      <div className="display-flex flex-wrap gap-15">
        <div className="form-group">
          <label htmlFor="inputWithMask1">Consumo veículo (km/litro)</label>
          <ClearableInput
            id="inputWithMask1"
            mask=""
            alwaysShowMask
            className="min-width-80"
          />
        </div>
        <div className="form-group-2">
          <label htmlFor="inputWithMask1">Preço combustível (R$/litro)</label>
          <ClearableInput
            id="inputWithMask1"
            mask=""
            alwaysShowMask
            className="min-width-90"
          />
        </div>
        <div className="btn-toolbar">
          <Button className="botao-com-calcular">Calcular</Button>
        </div>
      </div>
    );
  };

  const DynamicContentPreferencias = () => {
    return (
      <fieldset className="col-sm-4">
        <p>
          <Checkbox label="Evitar pedágio" />
        </p>
      </fieldset>
    );
  };

  const fetchDataAutosuggestAddress = async (text: string) => {
    const response = await fetchAutosuggest(text);
    const data: AutoSuggestSuggestion[] = [];
    response.forEach((i) => data.push({ label: i.title, ...i } as any));
    return data;
  };

  const DynamicContentDesvio = () => {
    const [value1, setValue1] = useState(4);
    return (
      <div className="display-flex flex-column gap-20">
        <div>
          <div className="display-flex flex-wrap gap-15">
            <div className="width-220">
              <Checkbox>Definir tolerância de desvio da rota</Checkbox>
              <Slider
                value={value1}
                minValue={0}
                maxValue={20}
                step={1}
                onDragEnd={(value) => {
                  Notification.info(`New value: ${value}`);
                  setValue1(value);
                }}
              />
            </div>
            <div className="width-150">
              <NumberInput placeholder="15Km" bsSize="sm" className="Test" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DynamicContentAreas = () => {
    const [tabKey, setTabKey] = useState<string>();
    return (
      <div>
        <DataTabs
          activeKey={tabKey}
          onSelectTab={(newTabKey) => setTabKey(newTabKey)}
        >
          <DataTab tabKey="tab1" title={<Title text="" value="Opcionais" />}>
            Nenhuma área opcional adicionada
          </DataTab>
          <DataTab
            tabKey="tab2"
            title={<Title text="" value="Globais" />}
          ></DataTab>
        </DataTabs>
        <div className="btn-toolbar max-width-500">
          <Button block>Block button</Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="max-width-500  display-grid gap-10">
        <div className="callout callout-primary border-none display-flex align-items-center shadow-smooth">
          <div className="flex-1">
            <div className="text-medium text-size-20 line-height-125rel display-flex align-items-center">
              <span className="rioglyph rioglyph-road text-size-16 margin-right-10"></span>
              <span className="text-size-16 text-medium line-height-15">
                Distância: {distance || "0KM"}
              </span>
            </div>

            <div>
              <span className="rioglyph text-color-gray rioglyph-filling-e-station text-size-16 margin-right-10"></span>
              <span className="text-color-gray text-size-12 line-height-15">
                Estimativa média de consumo de combustível:0l
              </span>
            </div>

            <div>
              <span className="rioglyph text-color-gray rioglyph-globe text-size-16 margin-right-10"></span>
              <span className="text-color-gray text-size-12 line-height-15">
                Estimativa média de emissão de carbono: 0kg CO2
              </span>
            </div>
          </div>
          <div
            className="display-flex justify-content-center"
            style={{ flex: 1 }}
          >
            <h1 className="text-color-primary text-size-40 font-weight-600 text-center line-height-15">
              {duration || "0h00min"}
            </h1>
          </div>
        </div>

        <div className="display-grid gap-10">
          <div>
            <div className="grid-colspan-1 grid-colspan-2-lg">
              <label className="text-color-dark">Origem</label>
            </div>
            <div>
              <div className="display-flex align-items-center">
                <Autosuggest
                  fetchData={fetchDataAutosuggestAddress}
                  placeholder="Informe a origem"
                  icon="rioglyph-start"
                  onSuggestionSelected={(e, i) =>
                    setOrigin({
                      ...(i.suggestion as any).position,
                      ...i.suggestion,
                    })
                  }
                />
                <div className="btn-toolbar">
                  <Button
                    bsStyle={Button.PRIMARY}
                    iconOnly
                    iconName="rioglyph-plus-light"
                    onClick={() => setOpenModal(true)}
                    className="margin-left-10"
                  />
                </div>
              </div>
              <div className="">
                <Collapse open={show} unmountOnExit>
                  <div>
                    <div className="row">
                      <div className="cargaDescarga">
                        <div className="col-1">
                          <div>
                            <button className="rioglyph rioglyph-chevron-up"></button>
                          </div>
                          <div>
                            <button className="rioglyph rioglyph-chevron-down"></button>
                          </div>
                        </div>
                        <div className="subTable">
                          <label className="text-color-dark">
                            Carga / Descarga - 20 min
                          </label>
                          <div className="input-group shadow-subtle">
                            <span className="input-group-addon">
                              <span className="rioglyph rioglyph-start" />
                            </span>
                            <input
                              className="form-control"
                              placeholder="Leme, SP, Brasil"
                            />
                            <div className="btn-toolbar">
                              <ToggleButton className="botao-com-mais">
                                +
                              </ToggleButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rota">
                      <div className="row">
                        <div className="col-1">
                          <div>
                            <button className="rioglyph rioglyph-chevron-up"></button>
                          </div>
                          <div>
                            <button className="rioglyph rioglyph-chevron-down"></button>
                          </div>
                        </div>
                        <div className="subTable-2">
                          <label className="text-color-dark">
                            Almoço / Descanso - 20 min
                          </label>
                          <div className="input-group shadow-subtle">
                            <span className="input-group-addon">
                              <span className="rioglyph rioglyph-start" />
                            </span>
                            <input
                              className="form-control"
                              placeholder="Leme, SP, Brasil"
                            />
                            <div className="btn-toolbar">
                              <ToggleButton className="botao-com-mais">
                                +
                              </ToggleButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
          <div>
            <div className="grid-colspan-1 grid-colspan-2-lg">
              <label className="text-color-dark">Destino</label>
            </div>
            <Autosuggest
              fetchData={fetchDataAutosuggestAddress}
              placeholder="Informe o destino"
              icon="rioglyph-finish"
              onSuggestionSelected={(e, i) =>
                setDestination({
                  ...(i.suggestion as any).position,
                  ...i.suggestion,
                })
              }
            />
          </div>
          <div className="separator ">
            <ExpanderPanel
              title="Otimizar"
              bsStyle="blank"
              titleClassName="text-bold margin-y-10"
              bodyClassName="padding-0"
            >
              <DynamicContent />
            </ExpanderPanel>

            <ExpanderPanel
              title="Preferências"
              bsStyle="blank"
              titleClassName="text-bold margin-y-10"
              bodyClassName="padding-0"
            >
              <DynamicContentPreferencias />
            </ExpanderPanel>

            <ExpanderPanel
              title="Desvio da rota"
              bsStyle="blank"
              titleClassName="text-bold margin-y-10"
              bodyClassName="padding-0"
            >
              <DynamicContentDesvio />
            </ExpanderPanel>

            <ExpanderPanel
              title="Áreas"
              bsStyle="blank"
              titleClassName="text-bold margin-y-10"
              bodyClassName="padding-0"
            >
              <DynamicContentAreas />
            </ExpanderPanel>

            <ExpanderPanel
              title="Simulador de custos"
              bsStyle="blank"
              titleClassName="text-bold margin-y-10"
              bodyClassName="padding-0"
            >
              <DynamicContentSimuilador />
            </ExpanderPanel>
          </div>
        </div>
      </div>

      <Dialog
        show={openModal}
        title={"Adicionar parada"}
        onClose={() => setOpenModal(false)}
        showCloseButton
        bsSize="md"
        body={
          <div className="display-flex">
            <div
              style={{
                width: 56,
                height: 56,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 24,
                marginLeft: 24,
              }}
              className="border border-color-primary border-width-3
                       text-center"
            >
              <span
                className="rioglyph rioglyph-pushpin text-color-primary"
                style={{ fontSize: 32 }}
              />
            </div>
            <div className="display-grid gap-10" style={{ flex: 1 }}>
              <div>
                <label className="text-color-dark">Local da parada</label>
                <Autosuggest
                  fetchData={fetchDataAutosuggestAddress}
                  placeholder="Informe a parada"
                />
              </div>
              <div className="form-group padding-left-0">
                <label htmlFor="select">Tipo de parada</label>
                <select className="form-control input-lg" id="select">
                  <option>Carga/descarga</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
              <div>
                <label htmlFor="time">Tempo de permanência</label>
                <input
                  id="time"
                  type="time"
                  className="form-control input-lg"
                  value="00:20"
                />
              </div>
            </div>
          </div>
        }
        footer={
          <div className="display-flex gap-10 justify-content-end">
            <Button
              bsStyle="primary"
              className="btn btn-primary btn-outline btn-lg width-100"
              onClick={() => setOpenModal(false)}
            >
              Cancelar
            </Button>
            <Button
              bsStyle="primary"
              className="btn-lg width-100"
              onClick={() => setOpenModal(false)}
            >
              Salvar
            </Button>
          </div>
        }
      />
    </>
  );
};
export default RegisterRoute;
