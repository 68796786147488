import React from 'react';
import PageContainer from '../../components/routes/PageContainer';
import TableContainer from '../../components/routes/TableContainer';
import { Header, Title, SearchInput } from '../../components/routes/Header';
import { Table, TableHeader, TableRow, TableCell, Button } from '../../components/routes/Table';
import { PaginationContainer, PaginationButton } from '../../components/routes/Pagination';
import { FiMapPin, FiAlertCircle } from 'react-icons/fi';

const routes = [
  {
    name: "Rota 1",
    origin: "São Paulo",
    destination: "Rio de Janeiro",
    stops: 3,
    duration: "5h 30m",
    distance: "430 km",
    company: "TransBrasil",
    wincon: "WINCon 001"
  },
  {
    name: "Rota 2",
    origin: "Curitiba",
    destination: "Florianópolis",
    stops: 2,
    duration: "4h 15m",
    distance: "300 km",
    company: "SulTrans",
    wincon: "WINCon 002"
  },
  {
    name: "Rota 3",
    origin: "Belo Horizonte",
    destination: "Vitória",
    stops: 4,
    duration: "6h 00m",
    distance: "520 km",
    company: "ViaEstrada",
    wincon: "WINCon 003"
  },
  {
    name: "Rota 4",
    origin: "Brasília",
    destination: "Goiânia",
    stops: 1,
    duration: "2h 45m",
    distance: "210 km",
    company: "CentroOeste",
    wincon: "WINCon 004"
  },
  {
    name: "Rota 5",
    origin: "Fortaleza",
    destination: "Recife",
    stops: 3,
    duration: "7h 10m",
    distance: "630 km",
    company: "NordesteTrans",
    wincon: "WINCon 005"
  },
  {
    name: "Rota 6",
    origin: "Salvador",
    destination: "Aracaju",
    stops: 2,
    duration: "4h 30m",
    distance: "325 km",
    company: "BahiaViagens",
    wincon: "WINCon 006"
  }
];

const handleCreateRoute = () => {
  const currentParams = new URLSearchParams(window.location.search);
  const url = '/routing/routes/create?' + currentParams.toString();
  window.location.href = url;
}


const RoutesScreen = () => {
  return (
    <PageContainer>
      <button style={{ width: 103 }} type="button" className="btn btn-primary btn-sm" onClick={handleCreateRoute}>
        <span className="rioglyph rioglyph-plus" aria-hidden="true" />
        <span>Criar rota</span>
      </button>
      <TableContainer>
        <Header>
          <Title>Selecionar rota para a viagem</Title>
          <SearchInput type="text" placeholder="Buscar rota..." />
        </Header>
        <Table>
          <thead>
            <tr>
              <TableHeader>Nome</TableHeader>
              <TableHeader>Origem</TableHeader>
              <TableHeader>Destino</TableHeader>
              <TableHeader>Paradas</TableHeader>
              <TableHeader>Duração</TableHeader>
              <TableHeader>Distância</TableHeader>
              <TableHeader>Cadastro</TableHeader>
              <TableHeader>WINCon</TableHeader>
              <TableHeader>Ação</TableHeader>
            </tr>
          </thead>
          <tbody>
            {routes.map((route, index) => (
              <TableRow key={index}>
                <TableCell>
                  <FiMapPin style={{ marginRight: '5px', color: '#1a6fb3' }} />
                  {route.name}
                </TableCell>
                <TableCell>{route.origin}</TableCell>
                <TableCell>{route.destination}</TableCell>
                <TableCell>{route.stops}</TableCell>
                <TableCell>{route.duration}</TableCell>
                <TableCell>
                  <FiAlertCircle style={{ marginRight: '5px', color: '#ff9800' }} />
                  {route.distance}
                </TableCell>
                <TableCell>{route.company}</TableCell>
                <TableCell>{route.wincon}</TableCell>
                <TableCell><Button>Selecionar</Button></TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
        <PaginationContainer>
          <PaginationButton>1</PaginationButton>
          <PaginationButton>2</PaginationButton>
          <PaginationButton>3</PaginationButton>
          <PaginationButton>4</PaginationButton>
          <PaginationButton>5</PaginationButton>
        </PaginationContainer>
      </TableContainer>
    </PageContainer>
  );
};

export default RoutesScreen;
