import { getLocation } from "@utils";
import api from ".";
import { IResponseAutosuggest, IResponseMountRoute } from "@types";

export const fetchAutosuggest = async (text: string) => {
  const { lat, lon } = await getLocation();

  const response = await api.get<IResponseAutosuggest>(
    "/v2/routes/autosuggest",
    {
      params: {
        lat,
        lon,
        text,
      },
    }
  );
  return response?.data?.items || [];
};

export const mountRoute = async (body: any) => {
  const response = await api.post<IResponseMountRoute>(
    "/v2/routes/mount",
    body
  );
  return response.data;
};

export const createAndSendRoute = async (body: any) => {
  const response = await api.post("v2/routes/rio/send", body);
  return response.data;
};
