import { Route, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "@store";
import screens from "@screens";
import { Page } from "@components";
import { ThemeProvider } from "styled-components";
import { theme } from "@theme";
import './styles/styles.css';
import { NotificationsContainer } from "@rio-cloud/rio-uikit";

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Page>
          <NotificationsContainer />
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/routing/map/trips" replace />}
            />
            <Route
              path="/routing/map/trips"
              Component={screens.OnGoingTripsScreen}
            />
            <Route path="/routing/trips" Component={screens.TripsScreen} />
            <Route path="/routing/zone" Component={screens.AreasScreen} />
            <Route path="/routing/routes" Component={screens.RoutesScreen} />
            <Route
              path="/routing/routes/create"
              Component={screens.RoutesCreateScreen}
            />
            <Route
              path="/routing/perfomance"
              Component={screens.PerformanceScreen}
            />
          </Routes>
        </Page>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
