import * as St from "./styles";
import Map from "@rio-cloud/rio-uikit/Map";
import Route from "@rio-cloud/rio-uikit/Route";
import SingleMapMarker, {
  SingleMapMarkerProps,
} from "@rio-cloud/rio-uikit/SingleMapMarker";
import MapSettings from "@rio-cloud/rio-uikit/MapSettings";
import MapTypeSettings from "@rio-cloud/rio-uikit/MapTypeSettings";
import { MapEvent, Position } from "@rio-cloud/rio-uikit/mapTypes";
import EventUtils from "@rio-cloud/rio-uikit/components/map/utils/eventHandling";
import Marker from "@rio-cloud/rio-uikit/Marker";
import React from "react";

export type Coordinates = {
  lat: number;
  lng: number;
};

interface Props {
  apiKey: string;
  //   routes?: Coordinates[];
  position?: Coordinates;
  boundingBox?: { top: number; left: number; right: number; bottom: number };
  segments: RouteSegment[];
  markers: SandboxMarker[];
}

export const getRandomValueToForceRerender = () => Math.random();

export type MarkerData = {
  markerType?: "custommarker";
  enableContextMenu?: boolean;
  contextMenuMarkerName?: string;
  lat?: number;
  lng?: number;
};

export type RoutePoint = Position & {};

export type RouteSegment = {
  points: RoutePoint[];
  data?: object;
  showArrows?: boolean;
  reduced?: boolean;
  alternative?: boolean;
};

export type SandboxMarker = {
  id: string;
  data?: MarkerData;
  type?: "addressType" | "customerPoiType" | "workshopPoiType";
  markerProps: SingleMapMarkerProps;
  position: Position;
};

export const getBoundingBox = (positions: Position[]) => {
  const lats = positions.map((position) => position.lat);
  const lngs = positions.map((position) => position.lng);
  const bbox = {
    top: Math.max(...lats),
    left: Math.min(...lngs),
    right: Math.max(...lngs),
    bottom: Math.min(...lats),
  };
  return bbox;
};

const Markers = ({ markers }: { markers: SandboxMarker[] }) => {
  const mapMarkers = markers.map((marker) => {
    const customData = { id: marker.id, data: marker.data };
    return (
      <Marker
        key={marker.id}
        customData={customData}
        position={marker.position}
        icon={<SingleMapMarker {...marker.markerProps} />}
      />
    );
  });
  return <>{mapMarkers}</>;
};

const Segments = ({ segments }: { segments: RouteSegment[] }) => {
  const mapSegments = segments.map((segment) => {
    return (
      <Route
        key={getRandomValueToForceRerender()}
        positions={segment.points}
        isRouteAlternative={segment.alternative}
        hasArrows={segment.showArrows}
      />
    );
  });
  return <>{mapSegments}</>;
};

const RioMap = ({
  apiKey,
  position,
  boundingBox,
  segments,
  markers,
}: Props) => {
  const getRandomValueToForceRerender = () => Math.random();
  return (
    <St.Container className="height-100pct">
      <Map
        key={getRandomValueToForceRerender()}
        credentials={{
          apikey: apiKey,
        }}
        language="de"
        center={position}
        boundingBox={boundingBox}
        zoom={10}
        hideMapSettings
        mapSettings={
          <MapSettings
            options={[
              <MapTypeSettings
                key="mapTypeSettings"
                tooltip="Change map type"
                dropdownHeaderText="Map views"
                defaultTypeLabel="Default view"
                truckTypeLabel="Truck view"
                terrainTypeLabel="Terrain view"
                satelliteTypeLabel="Satellite view"
                nightTypeLabel="Night view"
              />,
            ]}
          />
        }
      >
        {segments && <Segments segments={segments} />}
        {markers && <Markers markers={markers} />}
      </Map>
    </St.Container>
  );
};

export default RioMap;
