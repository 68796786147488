import { RegisterRouter } from "@components";
import { ApplicationLayout, Button, Sidebar } from "@rio-cloud/rio-uikit";
import { useAppSelector } from "@store";
import { IRoute, Route } from "@types";

type TProps = {
  sendRoute: (origin: IRoute, destination: IRoute, routes: Route[]) => void;
  handleRoutes: (routes: Route[], via: any[]) => void;
};

const SidebarRoute = ({ sendRoute, handleRoutes }: TProps) => {
  const { origin, destination, routes } = useAppSelector(
    (state) => state.routes
  );
  console.log(2, { origin, destination });
  return (
    <ApplicationLayout.Sidebar className="right">
      <Sidebar
        title="Rota"
        closed={false}
        onClose={() => {}}
        position={Sidebar.RIGHT}
        disableClose
        width={500}
      >
        <div className="padding-left-20 padding-top-20 padding-right-20">
          <RegisterRouter onChangeRoutes={handleRoutes} />
          <>
            <div className="btn-toolbar">
              <Button
                onClick={() =>
                  origin && destination
                    ? sendRoute(origin, destination, routes)
                    : null
                }
                block
                bsStyle={Button.PRIMARY}
              >
                Salvar rota
              </Button>
            </div>
            <br />
            {/* <div className="btn-toolbar">
              <Button
                onClick={() =>
                  origin && destination
                    ? sendRoute(origin, destination, routes)
                    : null
                }
                block
              >
                Salvar rota e criar viagem
              </Button>
            </div> */}
          </>
        </div>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};

export default SidebarRoute;
