import AreasScreen from "./areas";
import OnGoingTripsScreen from "./onGoingTrips";
import PerformanceScreen from "./performance";
import RoutesScreen from "./routes";
import RoutesCreateScreen from "./RoutesCreate";
import TripsScreen from "./trips";

export default {
    AreasScreen,
    OnGoingTripsScreen,
    PerformanceScreen,
    RoutesScreen,
    TripsScreen,
    RoutesCreateScreen,
}