import { RegisterRouter, RioMap } from "@components";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import Sidebar from "@rio-cloud/rio-uikit/Sidebar";
import Button from "@rio-cloud/rio-uikit/Button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSidebar } from "src/store/sidebar/SidebarSlice";
import { IRoute, Route } from "@types";
import { decode } from "src/utils/flexiblePolyline";
import {
  Coordinates,
  getBoundingBox,
  getRandomValueToForceRerender,
  RoutePoint,
  RouteSegment,
  SandboxMarker,
} from "src/components/Map";
import { getLocation } from "@utils";
import { SingleMapMarkerProps } from "@rio-cloud/rio-uikit/SingleMapMarker";
import SidebarRoute from "src/components/SidebarRoute";
import { createAndSendRoute } from "src/services/routes";
import Notification from '@rio-cloud/rio-uikit/Notification';

const RoutesCreateScreen = () => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState<Coordinates | undefined>({
    lat: -23.5475,
    lng: -46.63611,
  });
  const [boundingBox, setBoundingBox] = useState<
    { top: number; left: number; right: number; bottom: number } | undefined
  >();

  useEffect(() => {
    handleLocation();
  }, []);

  const handleLocation = async () => {
    const { lat, lon } = await getLocation();
    setPosition({ lat, lng: lon });
  };

  const [segments, setSegments] = useState<RoutePoint[]>([]);
  const [markers, setMarkers] = useState<SandboxMarker[]>([]);

  const sendRoute = async (
    origin: IRoute,
    destination: IRoute,
    routes: Route[]
  ) => {
    const body = {
      routeName: "teste",
      originRoute: {
        address: {
          label: origin.label,
        },
        addressStop: {
          label: origin.label,
        },
        lat: origin.lat,
        lng: origin.lng,
      },
      destinyRoute: {
        address: {
          label: destination.label,
        },
        addressStop: {
          label: destination.label,
        },
        lat: destination.lat,
        lng: destination.lng,
      },
      rangeToleranceLimit: 0,
      stops: [],
      roadParameters: {
        trafficConditions: "false",
        avoidToll: "false",
        avoidRoad: false,
        ignoreTrafficRestrictions: false,
      },
      vehicleVocationalInfo: {
        type: "TRUCK",
      },
      costs: {
        tollValue: "0",
        operativeCosts: "0",
        fuelAverageCosts: "0",
        averageConsume: "0",
        totalCosts: "0.00",
      },
      responsibleId: "felipy.cabral@opah.com.br",
      status: true,
      linkedVehicles: [],
      responseHere: { routes },
    };
    console.log({ body });
    

      try {
        const response = await createAndSendRoute(body);
        Notification.info(response.data.message)

        setTimeout(() => {
          window.location.href='/routing/routes'
        }, 1000);
      } catch (error) {
        Notification.error(error as string)
      }

  };

  useEffect(() => {
    dispatch(
      setSidebar(
        <SidebarRoute handleRoutes={handleRoutes} sendRoute={sendRoute} />
      )
    );
    return () => {
      dispatch(setSidebar(null));
    };

  }, []);

  const handleRoutes = (routes: Route[], via: any[]) => {
    if (routes.length) {
      let ArrayOfShape: any[] = [];
      const route = routes?.[0];
      const data = route?.sections || [];

      const getPolylineArray = data.map((item) => {
        return {
          shape: decode(item.polyline),
        };
      });

      const markers = data.reduce((acum: SandboxMarker[], curr, index, arr) => {
        if (index === 0) {
          const markerOrigin: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: ["start"],
              name: "",
              markerColor: "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.departure.place.location.lat,
              lng: curr.departure.place.location.lng,
            },
          };
          acum.push(markerOrigin);
        }
        if (index === arr.length - 1) {
          const markerDestination: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: ["finish"],
              name: "",
              markerColor: "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.arrival.place.location.lat,
              lng: curr.arrival.place.location.lng,
            },
          };
          acum.push(markerDestination);
        } else {
          const markerStopover: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: ["rioglyph rioglyph-pushpin"],
              name: via[index].type,
              markerColor: "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.arrival.place.location.lat,
              lng: curr.arrival.place.location.lng,
            },
          };
          acum.push(markerStopover);
        }

        return acum;
      }, []);

      getPolylineArray.forEach((item) => {
        ArrayOfShape.push(item.shape.polyline);
      });
      const concatArrays = ArrayOfShape.flat();
      const getPoints = concatArrays.map((value) => {
        return {
          lat: value[0].toString(),
          lng: value[1].toString(),
        };
      });
      setSegments(getPoints as RoutePoint[]);
      setMarkers(markers);
      if (getPoints?.length > 0) {
        setPosition(undefined);
        setBoundingBox(getBoundingBox(getPoints));
      } else if (markers?.length > 0) {
        setPosition(markers[0].position);
      }
    }
  };

  return (
    <RioMap
      position={position}
      boundingBox={boundingBox}
      apiKey={process.env.REACT_APP_RIO_MAP_KEY as string}
      segments={
        segments.length ? [{ points: segments, alternative: false }] : []
      }
      markers={markers}
    />
  );
};

export default RoutesCreateScreen;
