import React from 'react';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const PaginationButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  background: none;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #e5e9f2;
  }
`;

export { PaginationContainer, PaginationButton };
