import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

const Title = styled.h2`
  color: #1a6fb3;
  font-size: 1.5em;
`;

const SearchInput = styled.input`
  padding: 8px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
  max-width: 300px;
`;

export { Header, Title, SearchInput };
